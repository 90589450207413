<!--
 * @Description: 用户审核--用户实名审核-item页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors  : 蒙川
 * @LastEditTime : 2020-01-13 14:27:57
 -->
<template>
  <div class="realNameAuditList-item-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="new"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParams.userName" :maxlength="8"></v-input>
        <v-input label="手机号" v-model="searchParams.phone" :maxlength="11"></v-input>
        <v-select label="性别" v-model="searchParams.sex" :options="sexOps"></v-select>
        <v-select label="身份" v-model="searchParams.userType" :options="userTypeOps"></v-select>
        <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2>
        <v-select2
          label="所属公司"
          v-model="searchParams.regionId"
          v-bind="tenantParams"
        ></v-select2>
        <v-datepicker
          label="申请时间"
          type="rangedatetimer"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          :text="auditStatus === 0 ?'审核' : '查看'"
          type="text"
          :permission="auditStatus === 0 ? 'audit' : 'view'"
          @click="edit(scope.row)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  exportNoPassURL,
} from './api'

import { userTypeOps, sexOps } from './map'
import { communityParams, tenantParams } from 'common/select2Params'

export default {
  name: '',
  props: {
    status: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      userTypeOps: userTypeOps,
      sexOps: sexOps,
      communityParams,
      tenantParams,
      searchParams: {
        status: this.status,
        userName: '',
        phone: '',
        sex: undefined,
        userType: undefined,
        communityId: '',
        regionId: '',
        startTime: '',
        endTime: '',
        orderType: 1
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'realname',
          label: '认证姓名'
        },
        {
          prop: 'phone',
          label: '手机号'
        },
        {
          prop: 'sex',
          label: '性别'
        },
        {
          prop: 'userType',
          label: '身份'
        },
        {
          prop: 'communityAddress',
          label: '项目地址'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'intime',
          label: '申请时间'
        }
      ]
    }
  },

  computed: {
    auditStatus () {
      let auditStatus = this.status
      return auditStatus
    }
  },

  created () {
    this.exportUrl = this.auditStatus === 0 ? exportListURL : exportNoPassURL
  },
  mounted () {
    const { fromPage } = this.$route.query
    if (fromPage === 'workbench') {
      this.searchParams = {
        status: this.status,
        userName: '',
        phone: '',
        sex: undefined,
        userType: undefined,
        communityId: '',
        regionId: '',
        startTime: '',
        endTime: '',
        orderType: 1
      }
    }
  },

  methods: {
    edit (row) {
      this.$router.push({
        name: 'realNameAuditDetail',
        query: {
          id: row.id,
          ownerId: row.ownerId
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
    .realNameAuditList-item-wrapper {
        height: 100%;
    }
</style>
