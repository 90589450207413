var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "realNameAuditList-item-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          exportMethod: "new",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "姓名", maxlength: 8 },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "手机号", maxlength: 11 },
                  model: {
                    value: _vm.searchParams.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "phone", $$v)
                    },
                    expression: "searchParams.phone",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "性别", options: _vm.sexOps },
                  model: {
                    value: _vm.searchParams.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "sex", $$v)
                    },
                    expression: "searchParams.sex",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "身份", options: _vm.userTypeOps },
                  model: {
                    value: _vm.searchParams.userType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userType", $$v)
                    },
                    expression: "searchParams.userType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.tenantParams,
                    false
                  )
                ),
                _c("v-datepicker", {
                  attrs: {
                    label: "申请时间",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: {
                    text: _vm.auditStatus === 0 ? "审核" : "查看",
                    type: "text",
                    permission: _vm.auditStatus === 0 ? "audit" : "view",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }